import { useEffect, useState } from 'react'

export const useSidebarToggle = () => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'b') {
        e.preventDefault()
        setIsVisible((prev) => !prev)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return [isVisible, setIsVisible]
}
