import React, { useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { MdError, MdWarning, MdDone } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNotification, dismissNotification } from './actions'
import styles from './styles.module.scss'
import moment from 'moment'
import { PrimaryButton } from 'components/Button'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import LoadingCircle from 'components/LoadingIndicator/LoadingCircle'
import { selectUserId } from 'containers/App/selectors'
import { DeleteButton } from 'components/Button'
import { injectIntl } from 'react-intl'
import messages from './messages'
import parseUrl from 'utils/parseUrl'
import { Link } from 'react-router-dom'
import { FaExternalLinkAlt, FaLink } from 'react-icons/fa'

const STATUS_ICON = {
  Information: undefined,
  Warning: (
    <MdWarning color="#774b98" size={25} className={styles.severityIcon} />
  ),
  Error: <MdError color="red" size={25} className={styles.severityIcon} />,
  Success: <MdDone color="green" size={25} className={styles.severityIcon} />,
  Pending: <LoadingCircle size="small" className={styles.severityIcon} />,
}

const Notification = ({
  title,
  created,
  descriptions,
  link,
  status,
  ids,
  createdBy,
  intl: { formatMessage },
  companyCode,
  customerCode,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()
  const { internalLink, externalLink } = parseUrl(link)

  const titleToDisplay = title || formatMessage(messages.noName)
  const hasDescription = descriptions.some((description) => !!description)
  return (
    <div
      className={styles.notification}
      data-testing-id={`notification ${title}`}
    >
      <Row>
        <Col md={5} className={styles.justifyVerticalMiddle}>
          <div className={styles.title}>
            {STATUS_ICON[status]}
            {internalLink && (
              <Link to={internalLink}>
                {titleToDisplay} <FaLink />
              </Link>
            )}
            {externalLink && (
              <a href={externalLink} rel="noreferrer" target="_blank">
                {titleToDisplay} <FaExternalLinkAlt />
              </a>
            )}
            {!internalLink && !externalLink && titleToDisplay}
          </div>
        </Col>
        <Col md={1} className={styles.justifyVerticalMiddle}></Col>
        <Col md={6}>
          <div className={styles.actionButtons}>
            <ButtonGroup size="sm">
              {hasDescription && (
                <Button onClick={() => setShowDetails(!showDetails)}>
                  {showDetails
                    ? formatMessage(messages.less)
                    : formatMessage(messages.more)}
                </Button>
              )}
              {createdBy === userId && (
                <DeleteButton
                  onClick={() =>
                    ids.forEach((id) =>
                      dispatch(
                        deleteNotification({
                          notificationId: id,
                          companyCode,
                          customerCode,
                        })
                      )
                    )
                  }
                />
              )}
              <PrimaryButton
                onClick={() =>
                  ids.forEach((id) =>
                    dispatch(
                      dismissNotification({
                        notificationId: id,
                        companyCode,
                        customerCode,
                      })
                    )
                  )
                }
              >
                {formatMessage(messages.dismiss)}
              </PrimaryButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className={styles.time}>
            {moment(created)
              .locale('en')
              .fromNow()}
          </div>
        </Col>
      </Row>
      {showDetails && (
        <Row>
          <Col md={{ span: 10, offset: 1 }} className={styles.description}>
            <ListGroup variant="flush">
              {descriptions.map(
                (description, i) =>
                  !!description && (
                    <ListGroup.Item
                      key={`description-${i}`}
                      style={{
                        backgroundColor: 'inherit',
                      }}
                    >
                      {description}
                    </ListGroup.Item>
                  )
              )}
            </ListGroup>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default injectIntl(Notification)
