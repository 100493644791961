import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  MdBarChart,
  MdDashboard,
  MdHelp,
  MdHome,
  MdPerson,
} from 'react-icons/md'
import { GiPiggyBank, GiReceiveMoney } from 'react-icons/gi'
import { FaExternalLinkAlt } from 'react-icons/fa'
import PopupSection from './PopupSection'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserData } from 'containers/App/selectors'
import selectCustomers from 'containers/Customers/selectors'
import { useIsSystemUser } from 'utils/hooks/useHasClaim'
import { chooseProfile, logout } from 'containers/App/actions'
import CustomerCompanySelector from './CustomerCompanySelector'
import CollapsedSidebar from './CollapsedSidebar'

import styles from './styles.module.scss'
import FoldableSection from './FoldableSection'
import { useSidebarToggle } from './hooks/useSidebarToggle'
import { goToUrl, isLocal } from './functions'
import Notifications from 'containers/Notifications'

const getUrlPrefix = (customerCode, companyCode) => {
  return !customerCode
    ? `/`
    : !companyCode
    ? `/${customerCode}/`
    : `/${customerCode}/${companyCode}/`
}

function NavigationBar() {
  const { companyCode, customerCode } = useParams()
  const user = useSelector(selectUserData)
  const customers = useSelector(selectCustomers)
  const currentCustomer =
    customers.size === 1
      ? customers.first()
      : customers?.find((c) => c.code === customerCode)
  const currentCompany = currentCustomer?.companies?.find(
    (c) => c.code === companyCode
  )
  const isSystemUser = useIsSystemUser()
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useSidebarToggle()
  const dispatch = useDispatch()

  if (!isVisible && currentCustomer) {
    return <CollapsedSidebar setIsVisible={setIsVisible} />
  }

  return (
    <nav className={styles.sidebar}>
      <div className={styles.logoWrapper}>
        <CustomerCompanySelector
          currentCustomer={currentCustomer}
          currentCompany={currentCompany}
        />
      </div>

      <div className={styles.seperator} />

      {companyCode && (
        <>
          <Link
            to={
              user.get('defaultPage') ||
              `${customerCode}/${companyCode}/dashboards`
            }
          >
            <MdHome size={22} />
            <span>Home</span>
          </Link>
          <Link to={`${customerCode}/${companyCode}/dashboards`}>
            <MdDashboard size={22} />
            <span>Dashboards</span>
          </Link>
          <Link to={`${customerCode}/${companyCode}/budgets`}>
            <GiPiggyBank size={22} />
            <span>Budgets</span>
          </Link>
          <Link to={`${customerCode}/${companyCode}/reports-beta`}>
            <MdBarChart size={22} />
            <span>Reports</span>
          </Link>
          <Link to={`${customerCode}/${companyCode}/cashflow`}>
            <GiReceiveMoney size={22} />
            <span>Cash Flow</span>
          </Link>
          <div className={styles.seperator} />
          {currentCompany?.links?.length > 0 && (
            <>
              <FoldableSection
                title={
                  <span>{currentCompany.linksNavigationTitle || 'Tools'}</span>
                }
                show={true}
              >
                {currentCompany.links.map((link) =>
                  isLocal({ url: link.url }) ? (
                    <button
                      onClick={() => goToUrl({ url: link.url, navigate })}
                    >
                      <span>{link.name}</span>
                    </button>
                  ) : (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{link.name}</span>
                      <FaExternalLinkAlt
                        size={11}
                        style={{ marginLeft: '5px' }}
                      />
                    </a>
                  )
                )}
              </FoldableSection>
            </>
          )}
        </>
      )}
      {!companyCode && customerCode && (
        <>
          <Link to={`${customerCode}/accountschemetemplates`}>
            <span>Account Scheme Templates</span>
          </Link>
          <Link to={`${customerCode}/calculationconstants`}>
            <span>Calculation Constants</span>
          </Link>
          <Link to={`${customerCode}`}>
            <span>Companies</span>
          </Link>
          <Link to={`${customerCode}/customerdashboards`}>
            <span>Dashboards</span>
          </Link>
          <Link to={`${customerCode}/reportschemes`}>
            <span>Report Schemes</span>
          </Link>
          <Link to={`${customerCode}/reportschemetemplates`}>
            <span>Report Scheme Templates</span>
          </Link>
          <Link to={`${customerCode}/customerreports-beta`}>
            <span>Reports</span>
          </Link>
          <Link to={`customers/${customerCode}`}>
            <span>Settings</span>
          </Link>
          <Link to={`${customerCode}/users`}>
            <span>Users</span>
          </Link>
        </>
      )}
      {!companyCode && !customerCode && isSystemUser && (
        <>
          <Link to="/accountschemetemplates">
            <span>Account Scheme Templates</span>
          </Link>
          <Link to="/allusers">
            <span>All Users</span>
          </Link>
          <Link to="/calculationconstants">
            <span>Calculation Constants</span>
          </Link>
          <Link to="/customers">
            <span>Customers</span>
          </Link>
          <Link to="/systemdashboards">
            <span>Dashboards</span>
          </Link>
          <Link to="/gt/hangfire/">
            <span>GT Scheduled Jobs</span>
          </Link>
          <Link to="/integrationsourcesystems">
            <span>Integration source systems</span>
          </Link>
          <Link to="/reportschemes">
            <span>Report Schemes</span>
          </Link>
          <Link to="/reportschemetemplates">
            <span>Report Scheme Templates</span>
          </Link>
          <Link to="/systemreportselections-beta">
            <span>Reports</span>
          </Link>
          <Link to="/users">
            <span>Users</span>
          </Link>
        </>
      )}

      <FoldableSection
        title={
          <>
            <span>Company Settings</span>
          </>
        }
        show={!!companyCode}
      >
        <Link to={`${customerCode}/${companyCode}/accounts`}>
          <span>Accounts</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/accountingperiods`}>
          <span>Accounting Periods</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/accountschemes`}>
          <span>Account Schemes</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/accountschemetemplates`}>
          <span>Account Scheme Templates</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/calculationconstants`}>
          <span>Calculation Constants</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/dimensions`}>
          <span>Dimensions</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/reportinggroups`}>
          <span>Reporting Groups</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/reportschemes`}>
          <span>Report Schemes</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/rollingruletemplates`}>
          <span>Rolling Rule Templates</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/vouchers`}>
          <span>Vouchers</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/roles`}>
          <span>Roles</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/users`}>
          <span>Users</span>
        </Link>
        <Link to={`${customerCode}/companies/${companyCode}`}>
          <span>Settings</span>
        </Link>
        <Link to={`${customerCode}/integrationlogs`}>
          <span>Integration Logs</span>
        </Link>
      </FoldableSection>
      <div className={styles.flexGrow} />

      <a
        href="https://intercom.help/finazilla/fi/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.withIcon}
      >
        <MdHelp size={22} />
        <span>Help Center</span>
        <FaExternalLinkAlt size={11} style={{ marginLeft: '5px' }} />
      </a>
      <Notifications
        user={user}
        customerCode={customerCode}
        companyCode={companyCode}
      />
      <div className={styles.seperator} />
      <PopupSection
        title={
          <>
            <MdPerson size={22} />
            <div className={styles.withSubtitle}>
              <div className={styles.subTitle}>Signed in as</div>
              <div>{user.get('name')}</div>
            </div>
          </>
        }
      >
        <button
          onClick={() => {
            dispatch(logout())
          }}
        >
          <span>Logout</span>
        </button>
        <button
          onClick={() => {
            dispatch(chooseProfile(user, navigate))
          }}
        >
          <span>Switch Profile</span>
        </button>
        <Link
          to={
            getUrlPrefix(customerCode, companyCode) +
            `users/${user.get('id')}?tab=settings`
          }
        >
          <span>Settings</span>
        </Link>
      </PopupSection>
    </nav>
  )
}

export default NavigationBar
