import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { JsErrorComponent } from 'components/ErrorComponent/ErrorComponents'
import Dialogs from 'containers/Dialogs'
import NavigationBar from 'containers/NavigationBar'

import { useLocation } from 'react-router'
import { Outlet } from 'react-router-dom'
import LoadingIndicator from 'components/LoadingIndicator'
import { useSelector } from 'react-redux'
import { selectUserTheme } from 'containers/App/selectors'
import { Keys } from 'api/TokenStorage'
import useSetThemeToBody from 'utils/hooks/useSetThemeToBody'
import styles from './styles.module.scss'

window.addEventListener('storage', (e) => {
  if (
    (e.key === Keys.PROFILE_ID && e.newValue !== e.oldValue) ||
    (e.key === Keys.ACCESS_TOKEN && (!e.newValue || !e.oldValue))
  ) {
    window.location.reload()
  }
})

function LoggedInUser(props) {
  const theme = useSelector(selectUserTheme)
  const location = useLocation()
  useSetThemeToBody()
  return (
    <div className={`${theme} ${styles.container}`}>
      <ErrorBoundary FallbackComponent={JsErrorComponent}>
        <NavigationBar {...props} />
        <div className={styles.content}>
          <ErrorBoundary
            FallbackComponent={JsErrorComponent}
            resetKeys={[location]}
          >
            <React.Suspense fallback={<LoadingIndicator />}>
              <Outlet />
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </ErrorBoundary>
      <Dialogs />
    </div>
  )
}

export default LoggedInUser
