import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

const TooltipLinkPopup = ({ children, text, ...props }) => {
  const targetRef = useRef(null)
  const [showTooltip, setShowTooltip] = React.useState(false)

  const tooltip =
    showTooltip &&
    targetRef.current &&
    ReactDOM.createPortal(
      <div
        className={styles.tooltipPopup}
        style={{
          position: 'fixed',
          left: `${targetRef.current.getBoundingClientRect().right + 5}px`,
          top: `${targetRef.current.getBoundingClientRect().top +
            targetRef.current.getBoundingClientRect().height / 2}px`,
          transform: 'translateY(-50%)',
        }}
      >
        {text}
      </div>,
      document.body
    )

  return (
    <>
      <Link
        ref={targetRef}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        {...props}
      >
        {children}
      </Link>
      {tooltip}
    </>
  )
}

export default TooltipLinkPopup
