import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  MdBarChart,
  MdDashboard,
  MdHelp,
  MdHome,
  MdPerson,
  MdSettings,
} from 'react-icons/md'
import { GiReceiveMoney, GiPiggyBank } from 'react-icons/gi'
import PopupSection from './PopupSection'
import TooltipPopup from './TooltipPopup'
import { selectUserData } from 'containers/App/selectors'
import selectCustomers from 'containers/Customers/selectors'
import { chooseProfile, logout } from 'containers/App/actions'
import styles from './styles.module.scss'
import CustomerCompanySelector from './CustomerCompanySelector'
import Notifications from 'containers/Notifications'

const getUrlPrefix = (customerCode, companyCode) => {
  return !customerCode
    ? `/`
    : !companyCode
    ? `/${customerCode}/`
    : `/${customerCode}/${companyCode}/`
}

const CollapsedSidebar = ({ setIsVisible }) => {
  const { companyCode, customerCode } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(selectUserData)
  const customers = useSelector(selectCustomers)
  const currentCustomer =
    customers.size === 1
      ? customers.first()
      : customers?.find((c) => c.code === customerCode)
  const currentCompany = currentCustomer?.companies?.find(
    (c) => c.code === companyCode
  )

  return (
    <div
      className={styles.collapsedSidebar}
      id="collapsedSidebar"
      onClick={(e) => {
        if (e.target.id === 'collapsedSidebar') {
          setIsVisible(true)
        }
      }}
    >
      <div className={styles.logoWrapper} style={{ marginLeft: '10px' }}>
        <CustomerCompanySelector
          currentCustomer={currentCustomer}
          currentCompany={currentCompany}
          onlyIcon={true}
        />
      </div>
      <div className={styles.seperator} />
      {currentCompany && (
        <>
          <TooltipPopup
            to={
              user.get('defaultPage') ||
              `${customerCode}/${companyCode}/dashboards`
            }
            text="Home"
          >
            <MdHome size={22} />
          </TooltipPopup>
          <TooltipPopup
            to={`${customerCode}/${companyCode}/dashboards`}
            text="Dashboards"
          >
            <MdDashboard size={22} />
          </TooltipPopup>
          <TooltipPopup
            to={`${customerCode}/${companyCode}/budgets`}
            text="Budgets"
          >
            <GiPiggyBank size={22} />
          </TooltipPopup>
          <TooltipPopup
            to={`${customerCode}/${companyCode}/reports-beta`}
            text="Reports"
          >
            <MdBarChart size={22} />
          </TooltipPopup>
          <TooltipPopup
            to={`${customerCode}/${companyCode}/cashflow`}
            text="Cash Flow"
          >
            <GiReceiveMoney size={22} />
          </TooltipPopup>
          <div className={styles.seperator} />
        </>
      )}
      <PopupSection
        title={<MdSettings size={22} />}
        show={!!companyCode}
        direction="down"
      >
        <Link to={`${customerCode}/${companyCode}/accounts`}>
          <span>Accounts</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/accountingperiods`}>
          <span>Accounting Periods</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/accountschemes`}>
          <span>Account Schemes</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/accountschemetemplates`}>
          <span>Account Scheme Templates</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/calculationconstants`}>
          <span>Calculation Constants</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/dimensions`}>
          <span>Dimensions</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/reportinggroups`}>
          <span>Reporting Groups</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/reportschemes`}>
          <span>Report Schemes</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/rollingruletemplates`}>
          <span>Rolling Rule Templates</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/vouchers`}>
          <span>Vouchers</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/roles`}>
          <span>Roles</span>
        </Link>
        <Link to={`${customerCode}/${companyCode}/users`}>
          <span>Users</span>
        </Link>
        <Link to={`${customerCode}/companies/${companyCode}`}>
          <span>Settings</span>
        </Link>
      </PopupSection>
      <div className={styles.flexGrow} />
      <a
        href="https://intercom.help/finazilla/fi/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.withIcon}
      >
        <MdHelp size={22} />
      </a>
      <Notifications collapsed={true} />
      <div className={styles.seperator} />
      <PopupSection
        title={
          <span style={{ height: '48px' }}>
            <MdPerson size={22} />
          </span>
        }
      >
        <button
          onClick={() => {
            dispatch(logout())
          }}
        >
          <span>Logout</span>
        </button>
        <button
          onClick={() => {
            dispatch(chooseProfile(user, navigate))
          }}
        >
          <span>Switch Profile</span>
        </button>
        <Link
          to={
            getUrlPrefix(customerCode, companyCode) +
            `users/${user.get('id')}?tab=settings`
          }
        >
          <span>Settings</span>
        </Link>
      </PopupSection>
    </div>
  )
}

export default CollapsedSidebar
