import React from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import PopupSection from './PopupSection'
import defaultLogoSmall from './FinazillaLogoPNGSmall.png'
import selectCustomers from 'containers/Customers/selectors'
import { useSelector } from 'react-redux'
import styles from './styles.module.scss'
import { useIsSystemUser } from 'utils/hooks/useHasClaim'

const SEARCH_THRESHOLD = 7

function CustomerCompanySelector({
  currentCustomer,
  currentCompany,
  onlyIcon,
}) {
  const navigate = useNavigate()
  const customers = useSelector(selectCustomers)
  const isSystemUser = useIsSystemUser()

  const totalOptions = React.useMemo(() => {
    return (
      customers?.toJS()?.reduce((total, customer) => {
        return total + 1 + (customer.companies?.length || 0)
      }, 0) || 0
    )
  }, [customers])

  const options = React.useMemo(() => {
    const customerOptions =
      customers
        ?.toJS()
        ?.sort((a, b) => {
          if (a.code === currentCustomer?.code) return -1
          if (b.code === currentCustomer?.code) return 1
          return a.name.localeCompare(b.name)
        })
        ?.flatMap((customer) => [
          {
            label: `${customer.name} (${customer.code})`,
            name: customer.name,
            code: customer.code,
            value: customer.code,
            isCustomer: true,
            isCurrentCustomer: customer.code === currentCustomer?.code,
          },
          ...(customer.companies?.map((company, index) => ({
            label: `${company.name} (${company.code})`,
            name: company.name,
            code: company.code,
            value: `${customer.code}/${company.code}`,
            isCompany: true,
            customerId: customer.code,
            isCurrentCustomer: customer.code === currentCustomer?.code,
            isLastCompany: index === customer.companies?.length - 1,
          })) || []),
        ]) || []

    return customerOptions
  }, [customers, currentCustomer?.code])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '4px 12px',
      cursor: 'pointer',
      backgroundColor: state.isFocused
        ? 'rgba(255, 255, 255, 0.1)'
        : 'transparent',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
      marginTop: state.options[0] === state.data ? '4px' : 0,
      ...(state.data.isCustomer && {
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
      }),
      ...(state.data.isCompany && {
        paddingLeft: '24px',
      }),
      ...(state.data.isCurrentCustomer &&
        state.data.isLastCompany && {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        }),
    }),
    control: (provided) => ({
      ...provided,
      display: 'flex',
      minHeight: '32px',
      padding: '0 8px',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      border: 'none',
      borderRadius: '2px',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
      },
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
      margin: 0,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.7)',
    }),
    menu: (provided) => ({
      ...provided,
      position: 'relative',
      margin: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '400px',
      padding: 0,
      backgroundColor: 'transparent',
    }),
    container: (provided) => ({
      ...provided,
      position: 'relative',
      className: 'noClose',
      minWidth: '300px',
    }),
  }

  const handleChange = (selected) => {
    navigate(selected.value)
  }

  return (
    <PopupSection
      title={
        <>
          <img
            src={defaultLogoSmall}
            alt="Finazilla"
            style={{ marginRight: '5px', marginLeft: '-10px' }}
          />
          {!onlyIcon && (
            <div className={styles.withSubtitle}>
              <div className={styles.subTitle}>
                {!!currentCompany
                  ? 'Company / '
                  : !!currentCustomer
                  ? 'Customer / '
                  : ''}
                {currentCompany?.code || currentCustomer?.code}
              </div>
              <div>
                {currentCompany?.name ||
                  currentCustomer?.name ||
                  'System settings'}
              </div>
            </div>
          )}
        </>
      }
      direction="below"
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Select
          options={options}
          onChange={handleChange}
          styles={customStyles}
          placeholder="Search..."
          isClearable={false}
          autoFocus={totalOptions >= SEARCH_THRESHOLD}
          menuIsOpen={true}
          classNames={{
            control: () =>
              totalOptions >= SEARCH_THRESHOLD ? 'noClose' : 'd-none',
          }}
          hideSearchInput={totalOptions < SEARCH_THRESHOLD}
          formatOptionLabel={({ name, code }) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span style={{ paddingRight: '10px' }}>{name}</span>
              <span style={{ opacity: 0.7 }}>{code}</span>
            </div>
          )}
        />
        {isSystemUser && (
          <div
            className={styles.systemSettings}
            onClick={() => navigate('/')}
            style={{
              padding: '8px 12px',
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              marginTop: 'auto',
              cursor: 'pointer',
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            System settings
          </div>
        )}
      </div>
    </PopupSection>
  )
}

export default CustomerCompanySelector
