import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { loginRequest } from '../../authConfig'
import messages from './messages'

import Button from 'components/Button'

import { msalInstance } from 'containers/App/sagas'

import token from 'api/TokenStorage'
import { useNavigate } from 'react-router'
import { checkLogin } from 'containers/App/actions'
import { useSearchParams } from 'react-router-dom'

import styles from './styles.module.scss'

function AADLogin({ intl: { formatMessage } }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleLogin = async () => {
    const activeRedirect = await msalInstance.handleRedirectPromise()
    if (activeRedirect && activeRedirect.accessToken) {
      token.setTokens(activeRedirect.accessToken, activeRedirect.accessToken)
      dispatch(checkLogin({ navigate }))
      return
    }

    const activeEntraAccounts = msalInstance
      .getAllAccounts()
      .filter((acc) => acc.environment !== 'lemonsoftb2c.b2clogin.com')

    if (activeEntraAccounts.length > 0) {
      try {
        const accessTokenRequest = {
          ...loginRequest,
          account: activeEntraAccounts[0],
        }
        const tokenResponse = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        )
        console.log('Acquire entra token silent success', accessTokenRequest)
        const accessToken = tokenResponse.accessToken
        token.setTokens(accessToken, accessToken)
        dispatch(checkLogin({ navigate }))
        return
      } catch (err) {
        console.log('Acquire entra token silent failed', err)
      }
    }

    localStorage.setItem('redirectType', 'entra')
    msalInstance.loginRedirect(loginRequest)
  }

  useEffect(() => {
    if (searchParams.get('login_hint') === 'workorschoolaccount') {
      searchParams.delete('login_hint')
      setSearchParams(searchParams)
      handleLogin()
    }
  })

  return (
    <Button onClick={handleLogin} className={styles.microsoftLogin}>
      {formatMessage(messages.useLinkedAccount)}
    </Button>
  )
}
export default injectIntl(AADLogin)
