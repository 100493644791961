/**
 *
 * ButtonGroup - Bootsrap ButtonGroup Wrapper
 * - http://getbootstrap.com/components/#btn-groups
 *
 */

import PropTypes from 'prop-types'

import React from 'react'

import classNames from 'classnames'

const ButtonGroup = ({
  justified,
  size,
  toolbar,
  vertical,
  className,
  useMargin,
  ...props
}) => {
  const Tag = 'div'
  const role = toolbar ? 'toolbar' : 'group'
  const TagProps = {
    role,
    ...props,
  }

  const classes = classNames(
    vertical ? 'btn-group-vertical' : toolbar ? 'btn-toolbar' : 'btn-group',
    justified && 'btn-group-justified',
    size && `btn-group-${size}`,
    useMargin && 'btn-group-margin-right',
    className
  )

  return <Tag className={classes} {...TagProps} />
}

ButtonGroup.propTypes = {
  justified: PropTypes.bool,
  size: PropTypes.string,
  toolbar: PropTypes.bool,
  vertical: PropTypes.bool,
  useMargin: PropTypes.bool,
}

export default ButtonGroup
