import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import useOutsideAlerter from 'utils/hooks/useOutsideAlerter'

import styles from './styles.module.scss'

const randomCssValidIdString = () =>
  'abcdefghijklmnopqrstuvwxyz'
    .split('')
    .sort(() => Math.random() - 0.5)
    .slice(0, 13)
    .join('')

const PopupSection = ({
  title,
  children,
  show = true,
  direction = 'up',
  defaultOpen,
}) => {
  const [openPopup, setOpenPopup] = useState(defaultOpen)
  const triggerRef = useRef(null)
  const popupRef = useRef(null)
  const triggerId = useRef(randomCssValidIdString()).current

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && openPopup) {
        setOpenPopup(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [openPopup])

  useOutsideAlerter(popupRef, (e) => {
    if (
      openPopup &&
      e.target?.id !== triggerId &&
      e.target?.closest('#' + triggerId) === null
    ) {
      setOpenPopup(false)
    }
  })

  if (!show) return null

  const getPopupPosition = () => {
    if (!triggerRef.current) return {}
    const rect = triggerRef.current.getBoundingClientRect()
    if (direction === 'up') {
      return {
        left: rect.right + 4,
        bottom: window.innerHeight - rect.bottom,
      }
    }
    if (direction === 'down') {
      return {
        left: rect.right + 4,
        top: rect.bottom,
      }
    }
    if (direction === 'below') {
      return {
        left: rect.left,
        top: rect.bottom,
      }
    }
    return {
      left: rect.right + 4,
      top: rect.top,
    }
  }

  return (
    <div className={styles.popupContainer}>
      <div
        id={triggerId}
        ref={triggerRef}
        className={styles.popupTrigger}
        onClick={(e) => setOpenPopup(!openPopup)}
      >
        {title}
      </div>

      {openPopup &&
        createPortal(
          <div
            ref={popupRef}
            className={styles.portalPopup}
            onClick={(e) =>
              e.target?.closest('.noClose') === null && setOpenPopup(false)
            }
            style={getPopupPosition()}
          >
            {children}
          </div>,
          document.body
        )}
    </div>
  )
}

export default PopupSection
