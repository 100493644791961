import { useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md'
import styles from './styles.module.scss'

const FoldableSection = ({ title, children, show }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!show) return null

  return (
    <>
      <div
        className={`${styles.foldableHeader} ${styles.withIcon}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{title}</span>
        {isOpen ? (
          <MdKeyboardArrowDown size={16} className={styles.icon} />
        ) : (
          <MdKeyboardArrowRight size={16} className={styles.icon} />
        )}
      </div>
      {isOpen && <span className={styles.foldableContent}>{children}</span>}
    </>
  )
}

export default FoldableSection
