function getLuminance(color) {
  // Convert hex to RGB
  let r, g, b
  if (color.startsWith('#')) {
    const bigint = parseInt(color.slice(1), 16)
    r = (bigint >> 16) & 255
    g = (bigint >> 8) & 255
    b = bigint & 255
  } else if (color.startsWith('rgb')) {
    ;[r, g, b] = color.match(/\d+/g).map(Number)
  } else {
    throw new Error('Unsupported color format. Use hex or rgb.')
  }

  // Normalize RGB to 0-1 and apply the formula
  ;[r, g, b] = [r, g, b].map((c) => c / 255)
  ;[r, g, b] = [r, g, b].map((c) =>
    c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
  )

  // Calculate luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

function getContrastRatio(color1, color2) {
  const lum1 = getLuminance(color1)
  const lum2 = getLuminance(color2)

  // Ensure L1 is the lighter color
  const [L1, L2] = lum1 > lum2 ? [lum1, lum2] : [lum2, lum1]
  return (L1 + 0.05) / (L2 + 0.05)
}

export function getReadableTextColor(background) {
  const threshold = 3 // Minimum contrast ratio for normal text
  const white = '#FFFFFF'
  const black = '#000000'

  // Check contrast with white and black
  const contrastWithWhite = getContrastRatio(background, white)
  const contrastWithBlack = getContrastRatio(background, black)

  // Return the color with the better contrast
  if (contrastWithWhite >= threshold) {
    return white
  } else if (contrastWithBlack >= threshold) {
    return black
  }

  // If neither white nor black works, adjust dynamically
  let isDark = getLuminance(background) > 0.5 // Check if background is light or dark
  let [r, g, b] = isDark ? [0, 0, 0] : [255, 255, 255] // Start with black or white base

  while (true) {
    const color = `rgb(${r}, ${g}, ${b})`
    const contrast = getContrastRatio(background, color)

    if (contrast >= threshold) {
      return color
    }

    // Adjust the color slightly
    if (isDark) {
      r += 5
      g += 5
      b += 5 // Lighten the color
      if (r > 255) break // Exit if no readable color found
    } else {
      r -= 5
      g -= 5
      b -= 5 // Darken the color
      if (r < 0) break // Exit if no readable color found
    }
  }

  // Fallback to a default color if no suitable color is found
  return isDark ? '#FFFFFF' : '#000000'
}

export function isReadable(background, text) {
  const contrastRatio = getContrastRatio(background, text)
  const threshold = 3
  return contrastRatio >= threshold
}

export function getContrastedInvertedNavBarTextColor(
  background,
  normalTextColor
) {
  if (isReadable(background, normalTextColor)) {
    return normalTextColor
  }
  return getReadableTextColor(background)
}

export const isLocal = ({ url }) => {
  try {
    // eslint-disable-next-line no-restricted-globals
    return new URL(url)?.origin === location.origin
  } catch (e) {
    return false
  }
}

export const goToUrl = ({ url, navigate }) => {
  try {
    const newURL = new URL(url)
    // eslint-disable-next-line no-restricted-globals
    if (newURL.origin === location.origin)
      navigate({
        pathname: newURL.pathname,
        search: newURL.search,
      })
    else window.open(newURL.href)
  } catch (e) {
    console.warn(e)
  }
}
