import { CancelButton } from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import FormField from 'components/FormField'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-formal'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { createNotification } from './actions'
import {
  NOTIFICATION_SCOPES,
  NOTIFICATION_SCOPE_COMPANY,
  NOTIFICATION_SCOPE_SYSTEM,
  NOTIFICATION_SEVERITIES,
  NOTIFICATION_STATUS_INFORMATION,
  SENDABLE_NOTIFICATION_SEVERITIES,
} from './constants'
import moment from 'moment'

import messages from './messages'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useIsSystemUser } from 'utils/hooks/useHasClaim'
import { selectCurrentCompany } from 'containers/Companies/selectors'
import { SuccessButton } from 'components/Button'

const tagSchema = ({ company, scope }) =>
  yup.object({
    scope: yup
      .string()
      .oneOf(NOTIFICATION_SCOPES)
      .default(scope),
    companyCode: yup.string().default(company?.code),
    customerCode: yup.string().default(undefined),
    userId: yup.string().default(undefined),
    title: yup.string().required(),
    description: yup.string(),
    link: yup.string(),
    status: yup
      .string()
      .oneOf(NOTIFICATION_SEVERITIES)
      .default(NOTIFICATION_STATUS_INFORMATION),
    type: yup.string(),
    validTo: yup
      .date()
      .default(
        moment()
          .add(1, 'months')
          .toDate()
      )
      .min(moment().toDate()),
  })

const NotificationModal = ({
  onClose,
  // user,
  companyCode,
  customerCode,
  intl: { formatMessage },
}) => {
  const dispatch = useDispatch()
  const company = useSelector((state) =>
    selectCurrentCompany(state, { params: { companyCode, customerCode } })
  )
  const isSuperUser = useIsSystemUser()
  const getAllowedScopes = () => {
    const scopes = []
    if (company) scopes.push(NOTIFICATION_SCOPE_COMPANY)
    if (isSuperUser) scopes.push(NOTIFICATION_SCOPE_SYSTEM)
    return scopes
  }
  const handleSubmit = (form) => {
    dispatch(
      createNotification({
        notification: form,
        companyCode,
        customerCode,
      })
    )
    onClose()
  }
  return (
    <Form
      schema={tagSchema({})}
      onSubmit={handleSubmit}
      defaultValue={tagSchema({
        company,
        scope: getAllowedScopes()[0],
      }).default()}
    >
      <Row>
        <Col md={12}>
          <FormField
            type="dropdownlist"
            data={getAllowedScopes()}
            name="scope"
            label={formatMessage(messages.scope)}
            itemComponent={({ item }) =>
              item === NOTIFICATION_SCOPE_COMPANY ? company.name : item
            }
            valueComponent={({ item }) =>
              item === NOTIFICATION_SCOPE_COMPANY ? company.name : item
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormField name="title" label={formatMessage(messages.title)} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormField
            as="textarea"
            name="description"
            label={formatMessage(messages.description)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormField name="link" label={formatMessage(messages.link)} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormField
            type="dropdownlist"
            name="status"
            label={formatMessage(messages.status)}
            data={SENDABLE_NOTIFICATION_SEVERITIES}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormField
            type="datepicker"
            name="validTo"
            label={formatMessage(messages.validTo)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ButtonGroup className="pull-right" useMargin>
            <CancelButton onClick={onClose} />
            <SuccessButton type="submit">
              <FormattedMessage {...messages.sendNotifications} />
            </SuccessButton>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default injectIntl(NotificationModal)
